import React, { useContext, useEffect, useState } from "react";
import Controls from "../../components/controls/Controls";
import TimeZone from "../../components/controls/Timezone";
import useTable from "../../components/useTable";
import { FetchContext } from "../../context/FetchContext";
// import AddIcon from '@material-ui/icons/Add';
import { MoreHorizRounded } from "@material-ui/icons";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Popup from "../../components/Popup";
//import { Paper, Table, TableBody, TableCell, TableContainer, Tooltip, Toolbar, Typography, TableHead, TableRow } from '@mui/material';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Tooltip, Typography, makeStyles } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ExpandTable } from "../../components/controls/ExpandTable";
// import SessionStatus from './SessionStatus';
//import ActiveSessionsValues from './ActiveSessionsValues';
// import { MonthCalendar } from '@mui/x-date-pickers';
import { DateTime } from "luxon";
import ExportDTEData from "./ExportDTEData";
import ExportData from "./ExportData";
import Filter from "./Filter";
import SessionsPopUp from "./SessionsPopUp";
import { formatMaxPower } from "../../components/FormatMaxPower";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  searchInput: {
    width: "75%",
  },
  filterIcon: {
    position: "absolute",
    right: "50px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },

  exportIcon: {
    position: "absolute",
    right: "10px",
    cursor: "pointer",
  },
  exportDTEIcon: {
    position: "absolute",
    right: "100px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  pageTitle: {
    color: theme.palette.secondary.main,
    position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

const Sessions = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  //const [recordForEdit, setRecordForEdit] = useState(null)
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [transactionData, setTransactionData] = useState({});
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: "", subTitle: "" });
  const [showFilter, setShowFilter] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  //const dateStr = DateTime.now();
  //const [ filterPage, setFilterPage ] = useState(false);

  const [dTEdate, setDTEDate] = useState();

  const [filterObject, setFilterObject] = useState({
    manufacturer_id:null,
    session_type: null,
    uid:null,
    transaction_status: null,
    session_started_by: null,
    site_id: null,
    site_group_id: null,
    ocpp_cbid: null,
    max_energy: null,
    min_energy: null,
    session_id: null,
    from_date: null,
    to_date: null,
    export: false,
  });

  const headCells = [
    { id: "", label: "" },
    { id: "id", label: "ID" },
    { id: "charging_station", label: "Charging Station" },
    { id: "ocpp_cbid", label: "OCPP CBID" },
    { id: "site", label: "Site" },
    { id: "site_group", label: "Site Account" },
    { id: "connector_id", label: "CID" },
    { id: "energy_delivered", label: "Energy Delivered (KWH)" },
    { id: "amount", label: "Amount (USD)" },
    { id: "status", label: "Status" },
    { id: "session_start", label: "Session Start" },
    { id: "session_end", label: "Session End" },
    { id: "duration", label: "Duration" },
    { id: "", label: "" },
  ];

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page, filterObject) => {
    let startType = null;
    if (filterObject.session_type?.includes("enterprise")) {
      startType = "enterprise";
    } else if (filterObject.session_type?.includes("QR")) {
      startType = "QR";
    } else if (filterObject.session_type?.includes("mobileIOS")) {
      startType = "mobileios";
    } else if (filterObject.session_type?.includes("mobileAndroid")) {
      startType = "mobileandroid";
    }

    try {
      const { data } = await fetchContext.authAxios.post(
        //`/transactions?page=${page}`
        `/filter-transactions-relation?page=${page}`,
        {
          session_type: filterObject.session_type,
          transaction_status: filterObject.transaction_status,
          uid: filterObject?.uid,
          site_id: filterObject.site_id,
          site_group_id: filterObject.site_group_id,
          ocpp_cbid: filterObject.ocpp_cbid,
          max_energy: filterObject.max_energy,
          min_energy: filterObject.min_energy,
          session_id: filterObject.session_id,
          manufacturer_id: filterObject?.manufacturer_id,
          from_date: filterObject.from_date ? DateTime.fromJSDate(filterObject.from_date).toFormat("dd-MM-yyyy") : filterObject.from_date,
          to_date: filterObject.to_date ? DateTime.fromJSDate(filterObject.to_date).toFormat("dd-MM-yyyy") : filterObject.to_date,
          start_type: filterObject?.start_type,
          charger_group_id: filterObject.charger_group_id,
          export: filterObject.export,
        }
      );

      setRecords(data.data.data);
      setTotalRecord(data.data.total);


      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    getAll(fetchContext, page, filterObject);
  }, [fetchContext, page, filterObject]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  const openInPopup = (item) => {
    setTransactionData(item);
    setOpenPopup(true);
  };

  const onShowHideFilter = () => {
    setShowFilter((toggle) => !toggle);
    if (!showFilter) {
      setLoaded(false);
      setFilterObject({
        manufacturer_id:null,
        session_type: null,
        transaction_status: null,
        session_started_by:null,
        site_id: null,
        site_group_id: null,
        ocpp_cbid: null,
        max_energy: null,
        min_energy: null,
        session_id: null,
        from_date: null,
        to_date: null,
        export: false,
      });
    }
  };

  // const dateFormater = (value) => {
  //     switch (value) {
  //       case value.session_type.includes('enterprise'):
  //         return {session_type:'', start_type:''}
  //       case 'Yesterday':
  //         return moment(value).format('HH:mm')
  //       case 'Last 7 days':
  //         return moment(value).format('DD MMM')
  //       case 'Last 30 days':
  //         return moment(value).format('DD MMM')
  //       case 'This month':
  //         return moment(value).format('DD MMM')
  //       case 'This year':
  //         return moment(value).format("MMM 'YY")
  //       case 'Last year':
  //         return moment(value).format("MMM 'YY")
  //       case 'All time':
  //         return moment(value).format("MMM 'YY")
  //       default:
  //         return ''
  //     }
  //   }

  const onFilterSubmit = (values) => {
    //const tempFromDate = DateTime.now(values.from_date).toFormat(`dd-MM-yyyy'}`);
    let session_type = null;
    let start_type = null;
    let isSplit = false;
    let session_type_temp = values?.session_type;
    // if (values?.session_type) {
    //   let splited_string = values.session_type.split("|");
    //   console.log("splited_string", splited_string);
    //   if (splited_string?.length === 1 && splited_string[0] === "None") {
    //   } else if (splited_string?.length === 1) {
    //     session_type = splited_string[0];
    //   } else if (splited_string?.length === 3) {
    //     session_type = splited_string[0];
    //     start_type = splited_string[1];
    //     start_type = splited_string[1];
    //   }
    // }

    setPage(1);
    setLoaded(false);
    setFilterObject({
      session_type: values.session_type?.show 
        ? values.session_type?.session_type !== "None" 
          ? values?.session_type?.session_type : null
        : null,
      uid:values.session_type?.uid ? values.session_type?.uid : null,
      transaction_status: values.transaction_status !== "None" ? values.transaction_status : null,
      session_started_by: values.session_started_by !== "None" ? values.session_started_by : null,
      site_id: values.site_id !== "None" ? values.site_id : null,
      site_group_id: values.site_group_id !== "None" ? values.site_group_id : null,
      ocpp_cbid: values.ocpp_cbid,
      //manufacturer_id: values?.manufacturer_id,
      manufacturer_id: values?.manufacturer_id !== "None" ? values?.manufacturer_id : null,
      max_energy: values.max_energy,
      min_energy: values.min_energy,
      session_id: values.session_id,
      from_date: values.from_date,
      to_date: values.to_date,
      charger_group_id: values.charger_group_id,
      start_type:
        values?.session_type?.session_type === "REMOTE_TRANSACTION"
          ? values?.session_type?.start_type
          : !values?.session_type?.show
            ? values?.session_type?.session_type
            : null,
      export: false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);

    //resetForm()
    //setRecordForEdit(null)
    setOpenPopup(false);
    // setNotify({
    //     isOpen: true,
    //     message: 'Filtered Successfully',
    //     type: 'success'
    // })
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Sessions
            </Typography>
          </div>
          <div className={classes.filterIcon} onClick={onShowHideFilter}>
            {showFilter ? (
              <Tooltip title="Filter">
                <FilterAltIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Filter">
                <FilterAltOffIcon />
              </Tooltip>
            )}
          </div>
          <div className={classes.exportIcon}>
            <ExportData filterObject={filterObject} />
          </div>
          <div className={classes.exportDTEIcon}>
            DTE <ExportDTEData filterObject={filterObject} />
          </div>
        </Toolbar>

        {!showFilter && <Filter onFilter={onFilter} filterObject={filterObject} oncancle={onShowHideFilter} />}
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item, index) => {
                return (
                  <ExpandTable
                    expandedContent={
                      <TableContainer>
                        {/* <ActiveSessionsValues transaction_id={item.id}/> */}
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Plug In</TableCell>
                              <TableCell>Plug Out</TableCell>
                              <TableCell>SOC Start</TableCell>
                              <TableCell>SOC End</TableCell>
                              <TableCell>Type</TableCell>
                              {/* <TableCell>Amount</TableCell> */}
                              {/* <TableCell>Average Voltage</TableCell>
                                                <TableCell>Max Voltage</TableCell>   */}
                              {/* <TableCell>Average Power</TableCell> */}
                              <TableCell>Max Power</TableCell>
                              <TableCell>Mobile Number</TableCell>
                              <TableCell>Petroleum Displacement</TableCell>
                              <TableCell>GreenhouseGas</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                {item?.plug_in && item?.connector?.charging_stations?.site ? <TimeZone dateTime={item.plug_in} timezone={item?.connector?.charging_stations?.site.timezone} /> : "--"}
                              </TableCell>
                              <TableCell>
                                {item?.plug_out && item?.connector?.charging_stations?.site ? <TimeZone dateTime={item.plug_out} timezone={item?.connector?.charging_stations?.site.timezone} /> : "--"}
                              </TableCell>
                              <TableCell>{item.soc_start}%</TableCell>
                              <TableCell>{item.soc_end}%</TableCell>
                              <TableCell>
                                {item.type}
                                {item.type === "RFID"
                                  ? item?.rfid_tag
                                    ? `(${item?.rfid_tag?.name})`
                                    : ""
                                  : item.type === "REMOTE_TRANSACTION"
                                  ? item?.ocpi_session
                                    ? `${
                                        item?.uid ? `(${item?.uid})` : ""
                                      }`
                                    : `(${item.start_type})`
                                  : ""}
                              </TableCell>
                              {/* <TableCell>{item.type !== "FREE" && item.amount !== null && item.amount !== 0 ? item.amount : '--'}</TableCell> */}
                              {/* <TableCell>{item.average_voltage}</TableCell>
                                                <TableCell>{item.max_voltage}</TableCell>    */}
                              {/* {item.average_power && <TableCell>{parseInt(item.average_power)/1000}</TableCell>} */}
                              {/* <TableCell>{item.max_power}</TableCell> */}
                              <TableCell>{formatMaxPower(item.max_power, item.connector?.charging_stations?.manufacturer_id)}</TableCell>

                              <TableCell>
                                {(item?.frontend_user_id !== null || item?.frontend_user_id !== 0) && item?.user_information?.phone_number
                                  ? `*****${(item?.user_information?.phone_number).slice(-4)}`
                                  : "--"}
                              </TableCell>
                              {item.meter_start !== null && item.meter_end !== null ? (
                                <TableCell>{((item.meter_end - item.meter_start) / 1000 / 6).toFixed(2)}</TableCell>
                              ) : (
                                <TableCell>0.00</TableCell>
                              )}
                              {item.meter_start !== null && item.meter_end !== null ? (
                                <TableCell>{(((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2)}</TableCell>
                              ) : (
                                <TableCell>0.00</TableCell>
                              )}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    }
                    key={index}
                  >
                    <TableCell>{item.id}</TableCell>
                    <TableCell>
                      {item?.connector?.charging_stations?.name || "--"}
                    </TableCell>
                    <TableCell>
                      {item?.connector?.charging_stations?.ocpp_cbid || "--"}
                    </TableCell>
                    <TableCell>
                      {item?.connector?.charging_stations?.site?.name || "--"}
                    </TableCell>
                    <TableCell>
                      {item?.connector?.charging_stations?.site?.site_group
                        ?.name || "--"}
                    </TableCell>
                    <TableCell>
                      {item?.connector?.sequence_number || "--"}
                    </TableCell>
                    {/* {item.meter_start && item.meter_end ? <TableCell>{(item.meter_end - item.meter_start) / 1000}</TableCell> : <TableCell>--</TableCell>  }  */}
                    <TableCell>
                      {item.energy ? Number(item.energy).toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell>
                      {item.amount > 0 ? item.amount.toFixed(2) : "0.00"}
                    </TableCell>
                    {/* <TableCell>{item.type !== "FREE" && item.amount !== null && item.amount ? item.amount.toFixed(2) : "0.00"}</TableCell> */}
                    <TableCell>{item.status}</TableCell>
                    {/* <TableCell><SessionStatus transaction_id={item.id} /></TableCell> */}
                    <TableCell>
                      {item?.session_start && item?.connector?.charging_stations?.site ? <TimeZone dateTime={item.session_start} timezone={item?.connector?.charging_stations?.site.timezone} /> : "--"}
                    </TableCell>
                    <TableCell>{item?.connector?.charging_stations?.site && item.session_end ? <TimeZone dateTime={item.session_end} timezone={item?.connector?.charging_stations?.site.timezone} /> : "--"}</TableCell>
                    {item.session_start && item.session_end ? (
                      <TableCell>{DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat("hh:mm:ss")}</TableCell>
                    ) : (
                      <TableCell>--</TableCell>
                    )}
                    <TableCell>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <MoreHorizRounded fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  </ExpandTable>
                );
              })}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          //shape="rounded/circular"
          />
        </Stack> */}
        <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginTop:"20px"}}>
          <Typography
            spacing={2}
            style={{ color: "#8a0a0b"}}
            component="div"
          >
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup title="Meter Values" openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <SessionsPopUp transactionData={transactionData} />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default Sessions;
