import { Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import Notification from "../../components/Notification";
import Controls from "../../components/controls/Controls";
import { Form, useForm } from "../../components/useForm";
import { FetchContext } from "../../context/FetchContext";
import { DateTime } from 'luxon'; // Import DateTime from Luxon

const NotesForm = (props) => {
    const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });
    const { chargerId, setFormClose } = props;
    const fetchContext = useContext(FetchContext);
    const [notesList, setNotesList] = useState([]);

    const initialFValues = {
        note: "",
    };

    const getNotes = async () => {
        await fetchContext.authAxios.post(`/get-notes`, { id: chargerId.id }).then(async (response) => {
            await setNotesList(response?.data?.data?.data);
        });
    };

    useEffect(() => {
        getNotes();
    }, []);

    const validate = (fieldValues = values) => {
        let temp = { ...errors };
        setErrors({
            ...temp,
        });

        if (fieldValues === values) return Object.values(temp).every((x) => x === "");
    };

    const { values, setValues, errors, setErrors, handleInputChange, resetForm } = useForm(initialFValues, true, validate);

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchContext.authAxios
            .post(`/add-note`, { id: chargerId.id, note: values?.note })
            .then(({ data }) => {
                if (data.status) {
                    setNotify({
                        isOpen: true,
                        message: "Submitted Successfully",
                        type: "success",
                    });
                    resetForm();
                    getNotes();
                } else {
                    if (data.errors) {
                        setNotify({
                            isOpen: true,
                            message: `Something Went Wrong`,
                            type: "error",
                        });
                    }
                }
            })
            .catch((err) => {
                setNotify({
                    isOpen: true,
                    message: `Something Went Wrong`,
                    type: "error",
                });
            });
    };

    return (
        <>
            <Notification notify={notify} setNotify={setNotify} />
            <Form style={{ flexGrow: 1, maxWidth: '800px' }} onSubmit={handleSubmit}>
                <Grid container>
                    <Grid item xs={12}>
                        <Controls.Input
                            name="note"
                            label="Note"
                            required
                            value={values.note}
                            onChange={handleInputChange}
                            error={errors.note}
                            // disabled={false}
                            // readOnly={false}
                            style={{ width: "100%" }}
                        />
                    </Grid>

                    <Grid container>
  {notesList?.map((item, index) => (
    <Grid container key={index} style={{ marginBottom: '10px' }}>
      <Grid item xs={6}>
        <div style={{ marginRight: '10px' }}>{index + 1}. {item?.note}</div>
      </Grid>
      
      <Grid container item xs={6} style={{ backGround: 'green' }} >
      <Grid item xs={12} style={{ textAlign: 'right' }}> 
      {item.user_id ? `${item.user_id.first_name} ${item.user_id.last_name}` : ""}

     &nbsp;  &nbsp; &nbsp;
     <span  style={{ color: '#a31112' }} >{item.created_at ? DateTime.fromISO(item.created_at).toFormat('MM-dd-yyyy  HH:mm') : ""}</span>

        </Grid>
      </Grid>
    </Grid>
  ))}
</Grid>
                    <Grid item xs={12}>
                        <div>
                            <Controls.Button type="submit" text="Submit" />
                            <Controls.Button text="Cancel" color="default" onClick={(e) => setFormClose(false)} />
                        </div>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};

export default NotesForm;