import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from "@material-ui/core";

const ChargingStationFooter = () => {
  return (
    <div class="col-md-10 mt-2 alignright chargingstationfootericon" >
      {" "}
      <span class="indicatordot">
        <span class="greendot"></span> Available <Tooltip title='The charging station is ready to accept new charging sessions. It is online and operational, waiting for a vehicle to connect.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="yellowdot"></span> Charging <Tooltip title='The EV is actively being charged.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="reddot"></span> Faulty <Tooltip title='An error has occurred, and the station is unable to charge the vehicle. This status requires attention to identify and resolve the issue.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="grydot"></span> Unavailable <Tooltip title='The station is not operational and cannot accept charging sessions. This could be due to maintenance, a fault, or being offline.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="oragne2dot"></span> Suspended EVSE <Tooltip title='Charging is paused from the charging station&apos;s side, possibly due to an error or a manual intervention. The vehicle remains connected but isn &apos;t receiving power.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="browndot"></span> Suspended EV <Tooltip title='Charging has been paused due to the EV&apos;s request or an internal condition of the EV. The station is not supplying power but is still connected to the vehicle.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="bluedot"></span> Preparing <Tooltip title='The station is getting ready to start a charging session. Either a driver has plugged in and is entering their payment information, or a driver has entered their payment information and is about to plug in their vehicle.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
        <span class="blackdot"></span> Reserved <Tooltip title='The charging station is temporarily reserved by a driver.'><InfoOutlinedIcon /></Tooltip>
      </span>{" "}
      <span class="indicatordot">
    <span class="green2dot"></span> Finishing <Tooltip title='The charging session is completed and the vehicle is still plugged into the charger.'><InfoOutlinedIcon /></Tooltip>
      </span>
         {/* <span class="indicatordot">
        <span class="green2dot"></span> Unknown 
      </span> */}

    </div>
  );
};

export default ChargingStationFooter;
