import { ImportExport } from '@material-ui/icons';
import { Tooltip, CircularProgress, Snackbar } from '@mui/material'; // Import Snackbar for the toast notification
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';
import { FetchContext } from '../../context/FetchContext';
import { formatMaxPower } from '../../components/FormatMaxPower';


const ExportData = ({ filterObject }) => {
  const fetchContext = useContext(FetchContext);
  const [loading, setLoading] = useState(false); // State to manage loading
  const [showMessage, setShowMessage] = useState(false); // State to manage message display

function fetchConvertedDate(dateValue, timezone, formatType) {
  const dt = DateTime.fromISO(dateValue.replace(' ', 'T'), { zone: 'UTC' });

  // Convert to the specified timezone
  const dtConverted = dt.setZone(timezone);

  // Determine what to return based on formatType
  if (formatType === 'date') {
    return dtConverted.toLocaleString(DateTime.DATE_SHORT);
  } else if (formatType === 'time') {
    return dtConverted.toFormat('HH:mm:ss'); // 24-hour format with seconds
  } else {
    // Default case if formatType is not recognized, return both date and time
    return dtConverted.toLocaleString(DateTime.DATE_SHORT) + ' ' + dtConverted.toFormat('HH:mm:ss');
  }
}

  const user = localStorage.getItem('user_id');
const userId = parseInt(user); // Convert user ID to an integer if necessary

  const asyncFnComputeData = async () => {
    const dataArr = [];
    setLoading(true); // Set loading to true before fetching data
    setShowMessage(true); // Show message when starting download
    if (userId === 290 || userId === 315) {
      await fetchContext.authAxios.post(
        `/filter-transactions-complete-data`,
        {
          "session_type": filterObject.session_type,
          "site_id": filterObject.site_id,
          "ocpp_cbid": filterObject.ocpp_cbid,
          "max_energy": filterObject.max_energy,
          "transaction_status": filterObject.transaction_status,
          "site_group_id": filterObject.site_group_id,
          "min_energy": filterObject.min_energy,
          "session_id": filterObject.session_id,
          "from_date": filterObject.from_date ? DateTime.fromJSDate(filterObject.from_date).toFormat('dd-MM-yyyy') : filterObject.from_date,
          "to_date": filterObject.to_date ? DateTime.fromJSDate(filterObject.to_date).toFormat('dd-MM-yyyy') : filterObject.to_date,
          "start_type": filterObject?.start_type,
          "export": true,
          "charger_group_id": filterObject?.charger_group_id,
          "manufacturer_id":filterObject?.manufacturer_id
        }
      ).then(({ data }) => {
        const pdata = data.data;
        if (pdata) {
  
          for (let item of pdata) {
            let price_plan = item?.price_plan ? JSON.parse(item?.price_plan) : null
            //dataObj.sessionID = item.id;  
            let obj = {
              sessionID: item.id,
              chargingStation: item?.name,
              ocppCbid: item?.ocpp_cbid,
              site: item?.site_name,
              site_group: item?.site_group_name,
              connectorID: item?.sequence_number,
              type: `${item.type} ${item.type === "RFID" ? (item?.rfid_tag ? `(${item?.rfid_tag?.name})` : "") : (item.type === "REMOTE_TRANSACTION" ? (item?.party_name ? `(${item?.party_name})` : `(${item.start_type})`) : "")}`,
              status: item.status,
              amount: item?.amount > 0 ? item.amount.toFixed(2) : '0.00',
              // amount: item.type !== "FREE" && item.amount !== null && item.amount ? item.amount.toFixed(2) : "0.00",
              //energyDelivered: item.meter_end && item.meter_start ? ((item.meter_end - item.meter_start) / 1000).toFixed(2) : '',
              energyDelivered: item?.energy ? item?.energy : '',
              startedAt: item?.session_start ? fetchConvertedDate(item?.session_start, item?.timezone) : '',
              endedAt: item.session_end ? fetchConvertedDate(item?.session_end, item?.timezone) : '',
              duration: item?.session_end && item?.session_start ? DateTime.fromSQL(item.session_end).diff(DateTime.fromSQL(item.session_start)).toFormat('hh:mm:ss') : '',
              pluggedIn: item?.plug_in ? fetchConvertedDate(item?.plug_in, item?.timezone) : '',
              pluggedOut: item?.plug_out ? fetchConvertedDate(item?.plug_out, item?.timezone) : '',
              //pluggedDuration: item.plug_in && item.plug_out ? DateTime.fromSQL(item.plug_out).diff(DateTime.fromSQL(item.plug_in)).toFormat('hh:mm:ss') : '',
              startSOC: item?.soc_start,
              charger_type: item?.charger_type ?item?.charger_type:'' ,
              endSOC: item?.soc_end,
              //paymentReference: '',
              // maxPower: item?.max_power > 0 ? parseInt(item.max_power) / 1000 : '',
              // averagePower: item.average_power,
              // maxVoltage: item.max_voltage,
              // averageVoltage: item.average_voltage,
              // petroleumDisplacement: item.meter_start !== null && item.meter_end !== null ? (((item.meter_end - item.meter_start) / 1000) / 6).toFixed(2) : '',
              // greenhouseGas: item.meter_start !== null && item.meter_end !== null ? (((item.meter_end - item.meter_start) / 1000) * 1.3165).toFixed(2) : '',
              fixedFee: price_plan ? price_plan?.fixed_fee : '',
              variableFee: price_plan?.variable_fee ? price_plan?.variable_fee :"",
              feeType: price_plan ? price_plan?.fee_type : "",
              parkingFee: price_plan?.parking_fee ? price_plan?.parking_fee :"",
              parkingFeeUnit: price_plan?.parking_fee_unit ? price_plan?.parking_fee_unit :"",
              bufferTime: price_plan?.buffer_time ? price_plan?.buffer_time :"",
              taxPercentage: item?.taxes?.length ? item.taxes[0].tax_percentage : '',
              // Session Fee,
  
            };
            dataArr.push(obj);
          }
          setLoading(false); // Set loading to false after fetching data
          setShowMessage(false); // Show message when starting download
  
        }
      })
    }
    else{
      await fetchContext.authAxios
        .post(`/filter-transactions`, {
          session_type: filterObject.session_type,
          site_id: filterObject.site_id,
          ocpp_cbid: filterObject.ocpp_cbid,
          max_energy: filterObject.max_energy,
          transaction_status: filterObject.transaction_status,
          site_group_id: filterObject.site_group_id,
          min_energy: filterObject.min_energy,
          session_id: filterObject.session_id,
          from_date: filterObject.from_date
            ? DateTime.fromJSDate(filterObject.from_date).toFormat("dd-MM-yyyy")
            : filterObject.from_date,
          to_date: filterObject.to_date
            ? DateTime.fromJSDate(filterObject.to_date).toFormat("dd-MM-yyyy")
            : filterObject.to_date,
          start_type: filterObject?.start_type,
          export: true,
          charger_group_id: filterObject?.charger_group_id,
          manufacturer_id: filterObject?.manufacturer_id,
          uid: filterObject?.uid,
        })
        .then(({ data }) => {
          const pdata = data.data;
          if (pdata) {
            for (let item of pdata) {
              let obj = {
                sessionID: item.id,
                chargingStation: item?.name,
                ocppCbid: item?.ocpp_cbid,
                charger_type: item?.charger_type || "",
                site: item?.site_name,
                site_group: item?.site_group_name,
                connectorID: item?.sequence_number,
                type: `${item.type} ${
                  item.type === "RFID"
                    ? item?.rfid_tag
                      ? `(${item?.rfid_tag?.name})`
                      : ""
                    : item.type === "REMOTE_TRANSACTION"
                    ? item?.party_name
                      ? `${item?.uid ? `(${item?.uid})` : ""}`
                      : `(${item.start_type})`
                    : ""
                }`,
                status: item.status,
                amount: item?.amount > 0 ? item.amount.toFixed(2) : "0.00",
                energyDelivered: item?.energy.toFixed(2) || "0",

                // Charging Start Date and Time
                startedAt: item?.session_start
                  ? fetchConvertedDate(
                      item?.session_start,
                      item?.timezone,
                      "date"
                    )
                  : "",
                startedAtTime: item?.session_start
                  ? fetchConvertedDate(
                      item?.session_start,
                      item?.timezone,
                      "time"
                    )
                  : "",

                // Charging End Date and Time
                endedAt: item.session_end
                  ? fetchConvertedDate(
                      item?.session_end,
                      item?.timezone,
                      "date"
                    )
                  : "",
                endedAtTime: item.session_end
                  ? fetchConvertedDate(
                      item?.session_end,
                      item?.timezone,
                      "time"
                    )
                  : "",

                duration:
                  item?.plug_out && item?.plug_in
                    ? DateTime.fromSQL(item.plug_out)
                        .diff(DateTime.fromSQL(item.plug_in))
                        .toFormat("hh:mm:ss")
                    : "",

                // Session Start Date and Time
                pluggedIn: item?.plug_in
                  ? fetchConvertedDate(item?.plug_in, item?.timezone, "date")
                  : "",
                pluggedInTime: item?.plug_in
                  ? fetchConvertedDate(item?.plug_in, item?.timezone, "time")
                  : "",

                // Session End Date and Time
                pluggedOut: item?.plug_out
                  ? fetchConvertedDate(item?.plug_out, item?.timezone, "date")
                  : "",
                pluggedOutTime: item?.plug_out
                  ? fetchConvertedDate(item?.plug_out, item?.timezone, "time")
                  : "",
                maxPower: formatMaxPower(
                  item?.max_power,
                  item?.manufacturer_id
                ),

                startSOC: item?.soc_start,
                endSOC: item?.soc_end,
                totalPluggedDuration:
                  item?.session_end && item?.session_start
                    ? DateTime.fromSQL(item.session_end)
                        .diff(DateTime.fromSQL(item.session_start))
                        .toFormat("hh:mm:ss")
                    : "",
              };
              dataArr.push(obj);
            }
            setLoading(false); // Set loading to false after fetching data
            setShowMessage(false); // Show message when starting download
          }
        });
    }
    return Promise.resolve(dataArr);
  };

 const columns = [
  { 'id': 'sessionID', 'displayName': 'Session ID' },
  { 'id': 'chargingStation', 'displayName': 'Charging Station' },
  { 'id': 'ocppCbid', 'displayName': 'EVSE Serial Number' },
  { 'id': 'charger_type', 'displayName': 'Charger Type' },
  { 'id': 'site', 'displayName': 'Site Name' },
  { 'id': 'site_group', 'displayName': 'Site Account' },
  { 'id': 'connectorID', 'displayName': 'Connector ID' },
  { 'id': 'type', 'displayName': 'Type' },
  { 'id': 'status', 'displayName': 'Status' },
  { 'id': 'amount', 'displayName': 'Amount (USD)' },
  { 'id': 'energyDelivered', 'displayName': 'Total Energy Provided' },
  { 'id': 'startedAt', 'displayName': 'Charging Start Date' },
  { 'id': 'startedAtTime', 'displayName': 'Charging Start Time' },
  { 'id': 'endedAt', 'displayName': 'Charging End Date' },
   { 'id': 'endedAtTime', 'displayName': 'Charging End Time' },
  { 'id': 'totalPluggedDuration', 'displayName': 'Charging Duration' },
  
  { 'id': 'pluggedIn', 'displayName': 'Session Start Date' },
  { 'id': 'pluggedInTime', 'displayName': 'Session Start Time' },
  { 'id': 'pluggedOut', 'displayName': 'Session End Date' },
   { 'id': 'pluggedOutTime', 'displayName': 'Session End Time' },
  { 'id': 'duration', 'displayName': 'Session Duration' },
  { 'id': 'maxPower', 'displayName': 'Peak Power' },
  
  { 'id': 'startSOC', 'displayName': 'Start SOC' },
  { 'id': 'endSOC', 'displayName': 'End SOC' },
];

const columnsSingleUser = [
  { 'id': 'sessionID', 'displayName': 'Session ID' },
  { 'id': 'chargingStation', 'displayName': 'Charging Station' },
  { 'id': 'ocppCbid', 'displayName': 'OCPP CBID' },
  { 'id': 'charger_type', 'displayName': 'Charger Type' },
  { 'id': 'site', 'displayName': 'Site Name' },
  { 'id': 'site_group', 'displayName': 'Site Account' },
  { 'id': 'connectorID', 'displayName': 'Connector ID' },
  { 'id': 'type', 'displayName': 'Type' },
  { 'id': 'status', 'displayName': 'Status' },
  { 'id': 'amount', 'displayName': 'Amount (USD)' },
  { 'id': 'energyDelivered', 'displayName': 'Energy Delivered (kWH)' },
  { 'id': 'startedAt', 'displayName': 'Charging Start Date' },
  { 'id': 'startedAtTime', 'displayName': 'Charging Start Time' },
  { 'id': 'endedAt', 'displayName': 'Charging End Date' },
  { 'id': 'endedAtTime', 'displayName': 'Charging End Time' },
  { 'id': 'duration', 'displayName': 'Session Duration' },
  { 'id': 'pluggedIn', 'displayName': 'Session Start Date' },
  { 'id': 'pluggedInTime', 'displayName': 'Session Start Time' },
  { 'id': 'pluggedOut', 'displayName': 'Session End Date' },
  { 'id': 'pluggedOutTime', 'displayName': 'Session End Time' },
  { 'id': 'startSOC', 'displayName': 'Start SOC' },
  { 'id': 'endSOC', 'displayName': 'End SOC' },
  { 'id': 'fixedFee', 'displayName': 'Session Fee' },
  { 'id': 'variableFee', 'displayName': 'Variable Fee' },
  { 'id': 'feeType', 'displayName': 'Variable Fee Type' },
  { 'id': 'parkingFee', 'displayName': 'Parking Fee' },
  { 'id': 'parkingFeeUnit', 'displayName': 'Parking Fee Unit' },
  { 'id': 'bufferTime', 'displayName': 'Buffer Time' },
  { 'id': 'taxPercentage', 'displayName': 'Tax Percentage' },
  { 'id': 'totalPluggedDuration', 'displayName': 'Charging Duration' },
];


  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear() % 100;
  let finalDate = `${month}-${day}-${year}`;

  return (
    <>
      <CsvDownloader
        filename={`transactions(${finalDate})`}
        extension=".csv"
        separator=","
        columns={userId === 290 || userId === 315 ? columnsSingleUser : columns}
        datas={asyncFnComputeData}
      >
        <Tooltip title="Export / Download">
          {loading ? <CircularProgress size={24} /> : <ImportExport />}
        </Tooltip>
      </CsvDownloader>
      
      {/* Snackbar to display message */}
      <Snackbar
        open={showMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at top center
        style={{ backgroundColor: 'red' }} // Customize color to red
        message="Downloading data..."
      />
    </>
  );

};

export default ExportData;