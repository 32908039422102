import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText } from '@mui/material';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { FetchContext } from "../../context/FetchContext";
import Controls from "../../components/controls/Controls";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: "20vw",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  select: {
    //height: "10vh"
  },
  inputLabel: {
    //fontSize: "4vh",
    alignSelf: "center",
  },
}));

const TransactionTypeSelect = (props) => {
  const classes = useStyles();
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    required = false,
  } = props;
  const fetchContext = useContext(FetchContext);
  const [values, setValues] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getAllChargingStations = async () => {
      try {
        const { data } = await fetchContext.authAxios.get(
          "/select-session-types"
        );
        let apiData = data.data;
          let sample = [ 
            {
              session_type: "ac9964f166678c977f65814a88f8faf2499298072924101bc97da39d54873",
              start_type: "Ford",
              show: false,
              uid: "Ford",
            },
            {
              session_type: "ac9964f166678c977f65814a88f8faf2499298072924101bc97da39d54873",
              start_type: "Nissan",
              show: false,
              uid: "Nissan",
            },
            {
              session_type: "ac9964f166678c977f65814a88f8faf2499298072924101bc97da39d54873",
              start_type: "BMW",
              show: false,
              uid: "BMW",
            },
          ];

          // Step 1: Remove the specific API object and fetch its details
          let fordPassObject = apiData.find(
            item => item.start_type === "FordPass"
          );

          if (fordPassObject) {
            apiData = apiData.filter(item => item.start_type !== "FordPass");
            sample = sample.map(item => ({
              ...item,
              session_type: fordPassObject.session_type,
              show: fordPassObject.show,
            }));
          }
          const newArray = [...apiData, ...sample];
        setValues(newArray);
        setLoaded(true);
      } catch (err) {
        console.log("the err", err);
      }
    };
    getAllChargingStations(fetchContext);
  }, [fetchContext]);

  return loaded ? (
    <FormControl
      variant="standard"
      required={required}
      className={classes.formControl}
      style={{ width: "95%" }}
      {...(error && { error: true })}
    >
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <MuiSelect
        //style={{ width: "250px" }}
        className={classes.select}
        variant="standard"
        label={label}
        name={name}
        value={value}
        required={required}
        error={error}
        //options={values}
        //fullWidth={fullWidth}
        onChange={onChange}
      >
        {values?.map((op) => {
          return (
            <MenuItem
              value={op
                // op?.show
                //   ? `${op?.session_type}${
                //       op?.start_type ? `|${op?.start_type}|${op?.show}` : ""
                //     }`
                //   : `${op?.start_type}${
                //       op?.session_type ? `|${op?.session_type}|${op?.show}` : ""
                //     }`
              }
              style={{ width: "100%" }}
            >
              {/* {op?.show
                ? `${op?.session_type} ${
                    op?.start_type ? `(${op?.start_type})` : ""
                  }`
                : `${op?.start_type} ${
                    op?.session_type ? `(${op?.session_type})` : ""
                  }`} */}

                  {op?.show 
                  ? `${op?.session_type} ${
                    op?.start_type ? `(${op?.start_type})` : ""}` : 
                    `${op?.start_type} ${op?.session_type === "REMOTE_TRANSACTION" ?
                     `(${op?.session_type})` : ""}`}
            </MenuItem>
          );
        })}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  ) : (
    <Controls.Input
      name="site_id"
      label="Session Types"
      style={{ width: "95%" }}
    >
      {" "}
    </Controls.Input>
  );
};

export default TransactionTypeSelect;
