import {
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useState } from "react";
import ConfirmDialog from "../../components/ConfirmDialog";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import Popup from "../../components/Popup";
import Controls from "../../components/controls/Controls";
import useTable from "../../components/useTable";
import { AuthContext } from "../../context/AuthContext";
import { FetchContext } from "../../context/FetchContext";
import Filter from "./Filter";
import PricesForm from "./PricesForm";
import { DateTime } from "luxon";
// import { AuthContext } from '../context/AuthContext';
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(2),
    // padding: theme.spacing(1),
    // padding: theme.spacing(1),
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    [theme.breakpoints.between("sm", "md")]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.between("md", "lg")]: {
      padding: theme.spacing(1),
    },
  },
  searchInput: {
    width: "75%",
  },
  filterIcon: {
    // position: "absolute",
    // marginTop: "25px",
    right: "120px",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  newButton: {
    // position: "absolute",
    // right: "10px",
  },
  pageTitle: {
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "24px",
    },
    color: theme.palette.secondary.main,
    // position: "absolute",
    left: "10px",
    "& .MuiTypography-subtitle2": {
      opacity: "0.6",
    },
  },
  headerContainer: {
    // [theme.breakpoints.between("sm", "md")]: {
    //   padding: "18px 0",
    // },
    padding: "18px 0",
    alignItems: "center",
    [theme.breakpoints.between("md", "lg")]: {
      padding: "18px 0",
    },
  },
  exportIcon: {
    //position: 'absolute',
    marginTop: "25px",
    //right: '200px',
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  searchIcon: {
    marginTop: "25px",
    display: "inline",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  actionButtonCell: {
    textAlign: "right",
    paddingRight: "10px",
  },
}));

const Prices = () => {
  const fetchContext = useContext(FetchContext);
  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [counts, setCounts] = useState(1);
  //const [filterFn, setFilterFn] = useState({ fn: items => { return items; } })
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [showSearch, setShowSearch] = useState(true);
  const [totalRecord, setTotalRecord] = useState(0);

  const [filterObject, setFilterObject] = useState({
    search_keyword: null,
    // is_search: !showSearch,
    // export: false,
  });
  const auth = useContext(AuthContext);
  const [admin, setAdmin] = useState(auth);
  const headCells = [
    { id: "name", label: "Name" },
    { id: "fixed_fee", label: "Session Fee" },
    { id: "auth_amount", label: "Auth Amount" },
    { id: "fee_type", label: "Fee Type" },
    { id: "variable_fee", label: "Variable Fee" },
    { id: "parking_fee", label: "Parking Fee" },
    { id: "parking_type", label: "Parking Type" },
    { id: "buffer_time", label: "Buffer Time" },
    //{ id: 'actions', label: '', disableSorting: true }
  ];

  if (!fetchContext.isView) {
    headCells.push({ id: "actions", label: "", disableSorting: true });
  }

  const {
    TblContainer,
    TblHead,
    //TblPagination,
    //recordsAfterPagingAndSorting
  } = useTable(headCells);

  const getAll = async (fetchContext, page) => {
    try {
      const { data } = await fetchContext.authAxios.get(
        `/price-plans?page=${page}`
      );
      setRecords(data.data.data);
      setTotalRecord(data.data.total);
      if (data.data.total && data.data.total > 20) {
        let count = Math.ceil(data.data.total / 20);
        setCounts(count);
      } else {
        setCounts(1);
      }

      setLoaded(true);
    } catch (err) {
      console.log("the err", err);
    }
  };

  useEffect(() => {
    if (showSearch) {
      getAll(fetchContext, page);
    } else {
      filterAction(fetchContext, page, filterObject);
    }
  }, [fetchContext, page]);

  const handleChange = (event, value) => {
    if (value !== page) {
      setPage(value);
      setLoaded(false);
    }
  };

  // function convertFromUTCWithLuxon(utcTime, price_time_zone) {
  //   // Parse the UTC time
  //   const utcDate = DateTime.fromFormat(utcTime, "HH:mm", { zone: "utc" });

  //   // Convert back to the specified price_time_zone and return in "HH:mm" format
  //   return utcDate.setZone(price_time_zone).toFormat("HH:mm");
  // }

  function convertFromUTCWithLuxon(timeWithTimezone, timezone) {
    const [hours, minutes] = timeWithTimezone.split(":").map(Number);
    const dateTimeInTimezone = DateTime.fromObject(
      { hour: hours, minute: minutes },
      { zone: timezone }
    );
    const dateTimeInUTC = dateTimeInTimezone.toUTC();
    return dateTimeInUTC.toFormat("HH:mm");
  }


function convertScheduleToUTC(schedule, timezone) {
  const result = [];

  // Mapping of day names to Luxon's weekday values
  const dayToWeekday = {
    Sunday: 7,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  // Helper to calculate the UTC time and day
  const convertToUTCWithDay = (time, day, timezone) => {
    const [hours, minutes] = time.split(":").map(Number);

    // Get the corresponding weekday number for the given day
    const weekday = dayToWeekday[day];

    if (!weekday) {
      throw new Error(`Invalid day provided: ${day}`);
    }

    // Create a DateTime object in the specified timezone with the correct weekday
    const localDateTime = DateTime.fromObject(
      { hour: hours, minute: minutes, weekday },
      { zone: timezone }
    );

    // Convert to UTC
    const utcDateTime = localDateTime.toUTC();

    return {
      time: utcDateTime.toFormat("HH:mm"), // UTC time
      day: utcDateTime.toFormat("cccc"), // UTC-adjusted day
    };
  };

  schedule.forEach((entry) => {
    const { id, start_time, end_time, variable_fee, day } = entry;
    // Convert start and end times
    const startUTC = convertToUTCWithDay(start_time, day, timezone);
    const endUTC = convertToUTCWithDay(end_time, day, timezone);

    if (startUTC.day === endUTC.day) {
      // Both times fall on the same UTC day
      if (end_time === "23:59") {
        result.push({
          id,
          start_time: startUTC.time,
          end_time: endUTC.time,
          flag: true,
          variable_fee,
          day: startUTC.day, // UTC-adjusted day
        });
      } else {
        result.push({
          id,
          start_time: startUTC.time,
          end_time: endUTC.time,
          variable_fee,
          day: startUTC.day, // UTC-adjusted day
        });
      }
    } else {
      // Time range spans across two UTC days
      result.push({
        id,
        start_time: startUTC.time,
        end_time: "23:59",
        flag:true,
        variable_fee,
        day: startUTC.day, // First UTC day
      });
      result.push({
        id: id + 1, // Assign a new ID for the second entry
        start_time: "00:00",
        end_time: endUTC.time,
        variable_fee,
        day: endUTC.day, // Second UTC day
      });
    }
  });

  return result;
}
  const transformWeekSchedule = (weekSchedule, timeZone) => {
    const flatSchedule = [];
    for (const day in weekSchedule) {
      weekSchedule[day].forEach((entry) => {
        const [start_time, end_time] = entry.time.split(" - ");
        const variable_fee = entry.fee.replace("$", "");
        flatSchedule.push({
          id: entry.id,
          start_time: start_time,
          end_time: end_time,
          variable_fee,
          day,
        });
      });
    }
    const utcSchedule = convertScheduleToUTC(flatSchedule, timeZone);
    return utcSchedule;
  };

  const handleSubmit = (values, weekSchedule) => {
     const flatSchedule = transformWeekSchedule(
       weekSchedule,
       values?.price_time_zone
     );
    const payload = { ...values, weekly_variable_fee: flatSchedule };
    fetchContext.authAxios
      .post("/price-plans", payload)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Price Plan can't be created as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Price Plan can't be created as: ${err}`,
          type: "error",
        });
      });
  };

  const handleEdit = (id, values, weekSchedule) => {
      const flatSchedule = transformWeekSchedule(
        weekSchedule,
        values?.price_time_zone
      );
    const payload = { ...values, weekly_variable_fee: flatSchedule };
    fetchContext.authAxios
      .put(`/price-plans/${id}`, payload)
      .then(({ data }) => {
        if (data.status) {
          // getAll(fetchContext, page);
          filterAction(fetchContext, page, filterObject);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        } else {
          if (data.errors.name) {
            setNotify({
              isOpen: true,
              message: `Price Plan can't be edited as: ${data.errors.name[0]}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Price Plan can't be edited as: ${err}`,
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    // try {
    //   await fetchContext.authAxios.delete(`/price-plans/${id}`);
    //   getAll(fetchContext, 1);
    // } catch (err) {
    //   console.log(`Error: ${err.message}`);
    // }
    fetchContext.authAxios
      .delete(`/price-plans/${id}`)
      .then(({ data }) => {
        if (data.status) {
          getAll(fetchContext, page);
          setLoaded(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else {
          if (data.data) {
            setNotify({
              isOpen: true,
              message: `Price Plan can't be deleted as: ${data.data}`,
              type: "error",
            });
          }
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: `Price Plan can't be deleted as: ${err}`,
          type: "error",
        });
      });
  };

  const addOrEdit = (siteGroup, resetForm, weekSchedule) => {
    if (siteGroup.id === 0) handleSubmit(siteGroup, weekSchedule);
    else handleEdit(siteGroup.id, siteGroup, weekSchedule);
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    // setNotify({
    //     isOpen: true,
    //     message: 'Submitted Successfully',
    //     type: 'success'
    // })
  };

  const closeForm = () => {
    setOpenPopup(false);
  };

  const openInPopup = (item) => {
    const filteredData = item?.weekly_variable_fee?.filter(item => item.default_entry === 0);
    let newdata = {...item,weekly_variable_fee:filteredData}
    setRecordForEdit(newdata);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    handleDelete(id);
    // setNotify({
    //     isOpen: true,
    //     message: 'Deleted Successfully',
    //     type: 'error'
    // })
  };

  const onShowHideSearch = () => {
    setShowSearch(!showSearch);
    setLoaded(false);
    setPage(1);
    getAll(fetchContext, 1);
    setFilterObject({
      search_keyword: null,
      // "is_search": !showSearch,
      // "export": false,
    });
  };

  const onFilter = (filterValues) => {
    onFilterSubmit(filterValues);
  };

  const onFilterSubmit = (values) => {
    setPage(1);
    filterAction(fetchContext, page, values);
  };

  const filterAction = async (fetchContext, page, filterObject) => {
    fetchContext.authAxios
      .post(`/filter-price-plans?page=${page}`, filterObject)
      .then(({ data }) => {
        setRecords(data.data.data);
        setTotalRecord(data.data.total);
        if (data.data.total && data.data.total > 20) {
          let count = Math.ceil(data.data.total / 20);
          setCounts(count);
        } else {
          setCounts(1);
        }
        setLoaded(true);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        {/* <Toolbar>
          <div className={classes.pageTitle}>
            <Typography variant="h5" component="div">
              Prices
            </Typography>
          </div>
          {fetchContext.isAdmin && <div className={classes.searchIcon} onClick={onShowHideSearch}>
            {showSearch ? (
              <Tooltip title="Search">
                <SearchIcon />
              </Tooltip>
            ) : (
              <Tooltip title="Remove Search">
                <SearchOffIcon />
              </Tooltip>
            )}
          </div>}
          {!fetchContext.isView && (
            <Controls.Button
              style={{ color: "#a31112", border: "1px solid #a31112" }}
              text="Add New"
              variant="outlined"
              startIcon={<AddIcon />}
              className={classes.newButton}
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit(null);
              }}
            />
          )}
        </Toolbar> */}
        <div className={`row ${classes.headerContainer}`}>
          <div className="col">
            <div className={classes.pageTitle}>
              {/* <Typography variant="h5" component="div"> */}
              Prices
              {/* </Typography> */}
            </div>
          </div>
          <div className="col" style={{ textAlign: "right" }}>
            <div className="col" style={{ display: "inline-block" }}>
              {fetchContext.isAdmin && (
                <div className={classes.searchIcon} onClick={onShowHideSearch}>
                  {showSearch ? (
                    <Tooltip title="Search">
                      <SearchIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Remove Search">
                      <SearchOffIcon />
                    </Tooltip>
                  )}
                </div>
              )}
            </div>
            {/* <div className="col" style={{ display: "inline-block" }}>
              <div className={classes.exportIcon}>
                <ExportData filterObject={filterObject} />
              </div>
            </div> */}
            <div className="col" style={{ display: "inline-block" }}>
              {!fetchContext.isView && (
                <Controls.Button
                  style={{ color: "#a31112", border: "1px solid #a31112" }}
                  text="Add New"
                  isPaddingTop={false}
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => {
                    setOpenPopup(true);
                    setRecordForEdit(null);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!showSearch && (
          <Filter
            showSearch={showSearch}
            setFilterObject={setFilterObject}
            onFilter={onFilter}
            filterObject={filterObject}
            oncancle={onShowHideSearch}
          />
        )}
        <TblContainer>
          <TblHead />
          {loaded ? (
            <TableBody>
              {records.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.fixed_fee}</TableCell>
                  <TableCell>{item.auth_amount}</TableCell>
                  <TableCell>{item.fee_type}</TableCell>
                  <TableCell>{item.variable_fee}</TableCell>
                  <TableCell>{item.parking_fee}</TableCell>
                  <TableCell>{item.parking_fee_unit}</TableCell>
                  <TableCell>
                    {item.buffer_time ? item.buffer_time : "--"}
                  </TableCell>
                  {/* {(fetchContext.isAdmin || item.user_id === admin?.authState?.user_id) && ( */}
                  {!fetchContext.isView && (
                    <TableCell className="text-nowrap">
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="secondary"
                        onClick={() => {
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure to delete this record?",
                            subTitle: "You can't undo this operation",
                            onConfirm: () => {
                              onDelete(item.id);
                            },
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </Controls.ActionButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <Loader />
          )}
        </TblContainer>
        {/* <Stack direction={"row-reverse"} spacing={2}>
          <Pagination
            style={{ margin: "10px 10px 0 0" }}
            count={counts}
            page={page}
            onChange={handleChange}
            variant="outlined"
            color="secondary"
            siblingCount={0}
            boundaryCount={1}
          //shape="rounded/circular"
          />
        </Stack> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography spacing={2} style={{ color: "#8a0a0b" }} component="div">
            Total Records Found :{totalRecord}
          </Typography>
          <Stack direction={"row-reverse"} spacing={2}>
            <Pagination
              style={{ margin: "0 10px 0 0" }}
              count={counts}
              page={page}
              onChange={handleChange}
              variant="outlined"
              color="secondary"
              siblingCount={0}
              boundaryCount={1}
              //shape="rounded/circular"
            />
          </Stack>
        </div>
      </Paper>
      <Popup
        className="pricesmodel"
        title="Prices"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <PricesForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          closeForm={closeForm}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default Prices;
